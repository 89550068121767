#image-box{
    width: 324px;
    height: 250px;
}

#cropper-div{
    position: relative;
    width: 250px;
    height: 250px;
}

#submit-btn{
    width: 100%;
    background-color: #0b2435;
}
