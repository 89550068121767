#filter-btn {
  justify-content: center;
}

.search-feilds{
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  height: 40px;
  outline: none;
  box-shadow: 0px 4px 14px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 13px 10px 10px 8px;
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #999999;
}

.filter{
    position: absolute;
}

.stack-style{
  justify-content: space-between;
}

@media (max-width:930px) {
  #filter-btn {
    margin-top: 30px;
  }
}

@media ((min-width:1200px) and (max-width:1400px)){
  .stack-style{
    /* margin-right: 100px; */
    margin-left: 80px;
    display: flex;
    gap: 30px;
  }
}

@media ((max-width:930px)){
  .stack-style{
    flex-direction: column;
  }
}