.card-body {
    display: flex;
    flex-direction: row;
    width: 413px;
    gap: 40px;
    min-height: 222px;
    background: #EFF0F4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
    padding: 20px;
    z-index: 0;
    cursor: pointer;
}

.card-body .image {
    height: 135px;
    width: 135px;
    position: relative;
}

.card-body .image .country-flag {
    position: absolute;
    bottom: 0px;
    right: -15px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 52px;
    height: 52px;
    background: #EFF0F4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100%;
}

.card-body .image .country-flag .rect-flag img {
    width: 25px;
    height: 22px;
    border-radius: 0px;
}

.card-body .image .country-flag .flag {

    width: 100%;
    height: 100%;

}

.card-body .image img {
    height: 135px;
    width: 135px;
    border-radius: 25px;
}

#card-content {
    width: 70%;
    overflow: hidden;
}

#card-content a {
    text-decoration: none;
}

#card-right-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* width: 100%; */
    justify-content: space-between;
    margin-right: 5px;
    text-decoration: none;
}

#card-right-body a {
    text-decoration: none;
}

#card-title {
    display: flex;
    width: 80%;
    flex-direction: column;
    gap: 6px;
    /* word-wrap: break-word; */
}

#name {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 17px;
    line-height: 30px;
    /* identical to box height */

    color: #000000;
}

#profession {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 10px;
    /* identical to box height */


    color: rgba(51, 51, 51, 0.85);
}

.description-container {
    width: 100%;
    /* word-wrap: break-word; */
}

.description-container span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 12px;

    color: #000000;
    /* word-wrap: break-word; */
    /* word-break: break-all; */
}

#remainging {
    width: 100%;
    height: 100%;
}

#fav {
    width: 24px;
    height: 24px;
}

#selectedFav {
    width: 24px;
    height: 24px;
}

@media (max-width: 450px) {
    .card-body {
        min-width: 90%;
        max-width: 100%;
        height: 155px;
        gap: 20px;
    }

    .card-body .image .country-flag {
        bottom: 10px;
        right: -15px;

        width: 40px;
        height: 40px;
    }

    .card-body .image .country-flag .rect-flag img {
        width: 22px;
        height: 17px;
    }

    .card-body .image img {
        height: 110px;
        width: 110px;
    }

    #name {
        font-size: 14px;
        line-height: 20px;
    }

    #profession {
        font-size: 13px;
        line-height: 10px;
        margin-bottom: 5px;
    }

    .description-container {
        width: 90%;
    }

    .description-container span {
        font-size: 11px;
        line-height: 12px;
    }

}

@media (max-width: 350px) {
    .card-body {
        min-width: 90%;
        max-width: 100%;
        height: 145px;
        gap: 20px;
    }

    .card-body .image .country-flag {
        bottom: 15px;
        right: -15px;

        width: 40px;
        height: 40px;
    }

    .card-body .image .country-flag .rect-flag img {
        width: 22px;
        height: 17px;
    }

    .card-body .image img {
        height: 100px;
        width: 100px;
    }

    #name {
        font-size: 13px;
        line-height: 20px;
    }


    #fav {
        width: 24px;
        height: 24px;
    }

    #selectedFav {
        width: 24px;
        height: 24px;
    }

    #profession {
        font-size: 13px;
        line-height: 10px;
        margin-bottom: 5px;
    }

    .description-container {
        width: 95%;
    }

    .description-container span {
        font-size: 10px;
        line-height: 10px;
    }

}