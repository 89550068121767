.appointment-card {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  height: 105px;
  width: 100%;
}

.appointment-card:hover {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11), 0 4px 4px rgba(0, 0, 0, 0.11), 0 8px 8px rgba(0, 0, 0, 0.11), 0 16px 16px rgba(0, 0, 0, 0.11), 0 32px 32px rgba(0, 0, 0, 0.11);
}

.reschedule {
  margin-right: 10px;
  color: rgb(11, 36, 53);
}

#date {
  /* background-color: #256693; */
  color: #256693;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: white;
  height: 30px;
  padding: 0px 6px;
}

#time {
  /* background-color: rgb(232,131,222); */
  color: rgb(232, 131, 222);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: white;
  height: 30px;
  padding: 0px 6px;
}