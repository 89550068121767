.language-menu{
    text-transform: capitalize;
}

.file-upload-container {
    text-align: center;
    cursor: pointer;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    border:  1px dashed #938F99
}

.file-name-container{
    background-color: #E1EBFF;
    
}

.custom-file-upload {
    display: block;
    margin: auto;
}

.custom-file-upload i {
    font-size: 24px;
}

.custom-file-upload div {
    margin-top: 10px;
}
.file-upload-prompt{
    height: 40px;
}
.file-upload-success-prompt{
    height: 40px;
    background-color: #E1EBFF;
    color: rgba(0, 0, 0, 0.60);;
}
.cursor-pointer{
    cursor: pointer;
  }
.upload-font{
    color: #808080;
text-align: center;
font-family: Segoe UI;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
  .info-message{
    color: #808080;
    font-family: Segoe UI;
    font-size: 10.5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
