@font-face {
  font-family: 'DDC Hardware';
  src: url('../public/assets/fonts/DDCHardware-Regular.ttf') format('truetype');
}

body {
  overflow-x: hidden;
}

.blueGradient {
  background: linear-gradient(315.88deg, #0b2435 2%, #123a55 50.76%);
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.whiteTextLinks {
  color: white;
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.yellowIcons path {
  fill: #eab60f;
}

.yellowHeads {
  font-family: 'DDC Hardware';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #eab60f;
}

.pricingText {
  font-family: 'DDC Hardware';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 58px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;
  color: #123a55;
}

.sliderHead {
  font-family: 'Krona One';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  color: #eab60f;
}

.littleHead {
  font-family: 'Krona One';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-transform: uppercase;

  /* Main */

  color: #0b2435;
}

.subtitleWhite {
  font-family: 'HelveticaNeueCyr';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Light Text */

  color: #ffffff;
}

.littleHeadWhite {
  font-family: 'Krona One';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height */

  text-transform: uppercase;

  text-transform: uppercase;

  color: white;
}

.searchInput::placeholder {
  color: #123a55;
}

.label {
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  text-transform: uppercase;

  /* Main */

  color: #0b2435;
}

.labelSecondary {
  font-family: 'HelveticaNeueCyr';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  text-transform: uppercase;

  /* Main */

  color: #979797;
}

.primaryButton {
  background: #0b2435;
  border: 1px solid #0b2435;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;
}

.primaryButton2 {
  background: #0b2435;
  border: 1px solid #0b2435;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.secondaryButton {
  border: 1px solid #0b2435;
  border-radius: 4px;
  color: #0b2435;
  cursor: pointer;
}

.textSecondary {
  color: #6b8ea6;
  font-family: 'Inter';
}

.subtitle {
  font-family: 'HelveticaNeueCyr';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.subtitle5 {
  font-family: 'HelveticaNeueCyr';
  font-size: 24px;
  font-weight: 550;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}

.subtitleBold {
  /* Subtitle 2 Bold */
  font-family: 'HelveticaNeueCyr';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}

.remember {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  background-color: #0b2435;
}

input:checked~.checkmark {
  background-color: #0b2435;
}

.mutedFont {
  font-family: 'Krona One';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-transform: uppercase;

  color: #979797;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #0b2435 !important;
}

.scrollItems {
  overflow-y: scroll;
  max-height: 242px;
}

.scrollItem:hover {
  transition: 0.25s all;
  background-color: #6b8ea6;
}

.dateRowBox {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.appointmentsBox {
  max-width: 227px;
}

.tableHead {
  font-family: 'Krona One';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  text-transform: uppercase;

  color: #0b2435;
  border-color: #bdbdbd !important;
  border-bottom: 1px solid #bdbdbd !important;
}

.popupHead {
  font-family: 'Krona One';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  text-transform: uppercase;

  color: #0b2435;
}

.tableHead tr td {
  padding-top: 13px;
  padding-bottom: 13px;
}

.table tbody tr td {
  vertical-align: middle;
}

.table tbody tr {
  margin-top: 6px;
  margin-bottom: 6px;
}

.statusBtnCompleted {
  width: 120px;
  height: 40px;

  background: #256693;
  border: 1px solid #123a55;
  box-shadow: inset -2px -2px 11px #0b2435;
  border-radius: 4px;
  color: white;

  font-family: 'HelveticaNeueCyr', sans-serif;

  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
}

input[type='radio'] {
  transform: scale(2);
}

.statusBtnPending {
  width: 120px;
  height: 40px;

  background: #0b2435;
  border: 1px solid #0b2435;
  border-radius: 4px;
  color: white;
  text-transform: uppercase;
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.activeBtn {
  /* border-bottom: 2px solid #0B2435!important;
    
    border-radius: 0px!important; */
  /* text-decoration-line: underline !important;
  text-decoration-thickness: 15% !important ; */
  color: #0b2435 !important;
}

.tabs {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  /* identical to box height */

  color: #999999;
}

.tabActive {
  font-family: 'HelveticaNeueCyr';
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 14px;
  /* identical to box height */

  /* Main */

  color: #0b2435;

  border-bottom: 1px solid #0b2435;
}

.noticeActive {
  background-color: #edf1f4;
  border-left: 2px solid #6b8ea6;
}

/* .flexContainer::after {
  content: "";
  flex: auto;
} */

.gridContainer {
  display: grid;

  grid-template-columns: repeat(4, 1fr);

  grid-auto-rows: auto;

  grid-gap: 1rem;
}

.boxShadow {
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.11);
}

.boxShadow2 {
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.5);
}

/* testemonials */
.testPic {
  position: relative;
  bottom: -55px;
}

.testCard {
  padding: 20%;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.5);
  padding: 10%;
}

.textInter {
  color: #123a55;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.digit-group input {
  width: 40px;
  height: 40px;
  /* border: none; */
  /* line-height: 50px; */
  text-align: center;
  font-size: 24px;
  font-weight: 200;
  /* color: white; */
  margin: 0 2px;
}

.inputIconPass {
  position: absolute;
  right: 0px;

  padding: 9px 17px;

  top: 27px;
}

.timeRangeDisable {
  pointer-events: none;
  color: rgb(174, 172, 172);
}

.previewRangeDisable {
  pointer-events: none;
  color: rgb(37, 37, 37);
}

.deleteIconDiable {
  pointer-events: none;
  color: gray;
}

.greyText {
  color: gray;
}

.lightBorder {
  border: solid lightgray 1px;
}

.addBtnSchedule {
  padding: 5px;
  padding-left: 15px;
}

.activeSlot {
  color: white;
  background-color: #0b2435;
}

/* swiper navigation */
.swiper-pagination-bullet {
  background-color: #b1b6ba !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: #eab60f !important;
  width: 45px !important;
  border-radius: 19% !important;
}

.drawer {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  padding-top: 130px;
  align-items: center;
  /* width: 90%; */
}

.MuiButtonBase-root .MuiMenuItem-root .MuiMenuItem-gutters .Mui-selected {
  width: 20%;
}

.drawer-title {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.drawer-title span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 55%;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
}

.filter {
  display: flex;
  flex-direction: row;
  /* width: 65px; */
  justify-content: center;
  height: 19px;
  gap: 10px;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  cursor: pointer;
}

.verifyEmail {
  min-height: 100vh;
}

.subscriptionDetails {
  min-height: calc(100vh - 90px);
}

.edit-professional-profile {
  min-height: calc(100vh - 90px);
}

.pro-profile {
  min-height: calc(100vh - 90px);
}

.forgot-password {
  min-height: calc(100vh - 90px);
  overflow-y: scroll;
}

.how-it-works-link a {
  text-decoration: underline;
  text-decoration-color: #0b2435;
  color: #000000;
  font-weight: 500;
}

.how-it-works-link {
  text-decoration: none;
  color: #000000;
  font-weight: 500;
}

.payment-page {
  min-height: calc(100vh - 90px);
}

.table-user {
  height: 350px;
}

.table-user thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  border: 1px solid #DDDDDD;
  z-index: 1;
}

.notification-badge{
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fa6e81;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

#small-swiper-image {
  width: 200px;
  height: 150px;
}

#availability {
  color: rgb(18, 58, 85);
}

/* Navbar Logged */
#side-drawer {
  z-index: 1400;
}

/* crop image */
#crop-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  padding: 2rem;
}

@media (min-width: 900px) {
  .filter {
    visibility: hidden;
  }

  .drawer {
    box-shadow: none;
    padding: 0px;
  }

  .drawer-title {
    visibility: hidden;
  }
}


/* for mobile */
@media (max-width: 500px) {
  .sliderHead {
    font-size: 25px;
  }
}


@media (max-width: 390px) {
  .dialog_box {
    width: auto !important;
  }

  .confirm_btn {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .primary_btn {
    margin-bottom: 10px;
    width: 120px;
  }

  .secondary_btn {
    width: 120px;
  }
}

@media (max-width: 395px) {
  .container_div {
    width: auto !important;
  }
}

@media (max-width: 415px) {
  .diapop_up {
    width: auto !important;
  }

  .slider_professional {
    justify-content: end;
  }

  .slider_title {
    width: 100%;
  }
}